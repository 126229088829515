import m from "mithril";

import { Vec, nonNull, rotateArray } from "../geom";
import { globalState } from "../global-state";
import { exportOptionsForComponents } from "../io/export-options";
import { exportProjectExamples } from "../io/export-project-examples";
import {
  BooleanDifferenceDefinition,
  BooleanIntersectDefinition,
  BooleanUnionDefinition,
  ContractDefinition,
  DashedLinesDefinition,
  ExpandDefinition,
  FitWithinDefinition,
  FlattenDefinition,
  LayeredStackDefinition,
  LinearRepeatDefinition,
  MaskDefinition,
  MergePathsDefinition,
  MirrorRepeatDefinition,
  OutlineStrokeDefinition,
  RemoveHolesDefinition,
  RemoveOverlapsDefinition,
  RotationalRepeatDefinition,
  RoundCornersDefinition,
  TextAlongPathDefinition,
  TextWithinBoxDefinition,
  TileRepeatDefinition,
  TransformRepeatDefinition,
  VisibleDefinition,
  WarpCoordinatesDefinition,
  WeldAndScoreDefinition,
} from "../model/builtin-modifiers";
import {
  CompoundPathDefinition,
  GroupDefinition,
  PathDefinition,
  StrokeDefinition,
} from "../model/builtin-primitives";
import { DependencyGraph } from "../model/dependency-graph";
import { GuidesDisplay } from "../model/element";
import { ExportFormat } from "../model/export-format";
import { Expression } from "../model/expression";
import { ComponentFocus } from "../model/focus";
import { Instance } from "../model/instance";
import { InstanceDefinition } from "../model/instance-definition";
import { Modifier } from "../model/modifier";
import { Project } from "../model/project";
import { alignNodes, flipNodes } from "../model/transform-utils";
import { accountState } from "../shared/account";
import { checkUserHasProFeature } from "../shared/feature-check";
import { Icon20, IconName } from "../shared/icon";
import { Accelerator, registerKeyboardShortcut } from "../shared/keyboard";
import {
  CreatedPopup,
  MenuItem,
  createPopupMenu,
  createRightClickPopupMenu,
} from "../shared/popup";
import { createPopupPrompt } from "../shared/popup-prompt";
import { classNames, domForVnode, isMacPlatform } from "../shared/util";
import { urlParts } from "../util";
import { rulerWidthPixels } from "./canvas-ui/canvas-ruler";
import { openExportOptionsModal } from "./export-modal";
import { downloadFileWithExportOptionsAndErrorToast, printFocusedComponent } from "./export-utils";
import { pickFilesForImport } from "./image-upload";
import { importFilesToProject } from "./import-to-project";
import { RectWatcher } from "./rect-watcher";
import {
  badgeSelectedNodesWithModifier,
  badgeSelectedNodesWithModifierAction,
  badgeSelectedNodesWithNewModifierAction,
  bringSelectedNodesForward,
  bringSelectedNodesToFront,
  convertSelectedNodesToPaths,
  extractAsComponent,
  removeUnusedModifiersAction,
  sendSelectedNodesBackward,
  sendSelectedNodesToBack,
  ungroupAllSelectedNodes,
  ungroupSelectedNodes,
  unwrapSelectedNodesWithDefinition,
  wrapProjectSelectionInModifier,
  wrapProjectSelectionInModifierAction,
} from "./top-menu-actions";
import {
  menuCopySelection,
  menuCutSelection,
  menuPasteSelection,
  onDocumentCopy,
  onDocumentCut,
  onDocumentPaste,
} from "./top-menu-clipboard";
import { TopMenuProject } from "./top-menu-project";
import { ShareMenu } from "./top-menu-share";
import {
  PANEL_TOP_HEIGHT,
  showAdminFeatures,
  toggleAdminProTesting,
  toggleShowAdmin,
} from "./util";

const modifierMenuItem = (
  label: string,
  definition: Modifier,
  options?: { isBetaFeature?: boolean }
): MenuItem => {
  const isBeta = Boolean(options?.isBetaFeature);
  const item: MenuItem = {
    label: isBeta ? [label, m(".feature-tag", "BETA")] : label,
    icon: () => definition.icon && m(Icon20, { icon: definition.icon }),
    enabled: hasSelection,
    action: () => {
      if (!isBeta || checkUserHasProFeature("beta")) {
        badgeSelectedNodesWithModifier(definition);
      }
    },
  };
  return item;
};

const newProject = () => {
  if (globalState.storage.type === "local") {
    globalState.project = new Project();
  } else {
    window.open("/new-project");
  }
};

const hasSelection = () => {
  return !globalState.project.selection.isEmpty();
};
const hasMultipleSelection = () => {
  return globalState.project.selection.isMultiple();
};
const hasFocusedComponent = () => globalState.project.hasFocusedComponent();

const selectionContainsNodeWithDefinition = (definition: InstanceDefinition) => {
  return globalState.project.selection
    .directlySelectedNodes()
    .items.some(({ node }) => node.hasDefinition(definition));
};
const hasSelectionWithGroup = () => selectionContainsNodeWithDefinition(GroupDefinition);
const hasSelectionWithCompoundPath = () =>
  selectionContainsNodeWithDefinition(CompoundPathDefinition);

// These menu items are shared between right click selection and the top menus.

const duplicateMenuItem: MenuItem = {
  label: "Duplicate",
  accelerator: new Accelerator("d", { command: true }),
  action: () => globalState.project.duplicateSelection(),
  enabled: hasSelection,
};
const groupMenuItem: MenuItem = {
  label: "Group",
  accelerator: new Accelerator("g", { command: true }),
  action: wrapProjectSelectionInModifierAction(GroupDefinition),
  enabled: hasSelection,
};
const ungroupMenuItem: MenuItem = {
  label: "Ungroup",
  accelerator: new Accelerator("g", { command: true, shift: true }),
  action: ungroupSelectedNodes,
  enabled: hasSelectionWithGroup,
};
const ungroupAllMenuItem: MenuItem = {
  label: "Ungroup All",
  action: ungroupAllSelectedNodes,
  enabled: hasSelectionWithGroup,
};
const splitPathAtAnchorMenuItem: MenuItem = {
  label: "Split Path at Anchor",
  action: () => {
    const selection = globalState.project.selection.directlySelectedNodes();
    for (const anchorNode of selection.toNodes()) {
      if (anchorNode.isAnchor()) {
        const pathNode = anchorNode.parent;
        if (!pathNode || !pathNode.isPath()) continue;

        const closedExpr = pathNode.source.base.expressionForParameterWithName("closed");
        if (!closedExpr?.isLiteral()) continue;

        const isClosed = Boolean(closedExpr.literalValue());
        if (isClosed) {
          // Open the path.
          pathNode.source.base.args.closed = new Expression("false");

          // Rotate the path's children so that the selected anchor is first.
          const anchorIndex = anchorNode.indexInParent();
          rotateArray(pathNode.sourceChildren(), anchorIndex);

          // Make a copy of the anchor and append it to the path.
          const newAnchorElem = anchorNode.source.clone();
          const newNodes = globalState.project.spliceNodes([], [newAnchorElem], pathNode);
          globalState.project.selectNodes(newNodes);
        } else {
          if (!pathNode.parent) continue;

          const anchorIndex = anchorNode.indexInParent();

          // Can't split at the endpoints.
          if (anchorIndex === 0) continue;
          if (anchorIndex === pathNode.childCount() - 1) continue;

          // Copy the path and split its children
          const newPathElem = globalState.project.duplicateElementWithoutChildren(pathNode.source);
          const [newPathNode] = globalState.project.spliceNodes([], [newPathElem], pathNode.parent);

          // Copy the anchor and append it to the new path.
          const newAnchorElem = globalState.project.duplicateElement(anchorNode.source.clone());
          globalState.project.spliceNodes([], [newAnchorElem], newPathNode);

          // Move the rest of the childrn after the anchor to the new path.
          globalState.project.reparentNodes(
            pathNode.childNodes().slice(anchorIndex + 1),
            newPathNode
          );
          globalState.project.selectNode(newPathNode);
        }
      }
    }
  },
  enabled: () => globalState.project.selection.directlySelectedNodes().isAllAnchors(),
};
const reversePathDirectionMenuItem: MenuItem = {
  label: "Reverse Path Direction",
  action: () => {
    for (let item of globalState.project.selection.items) {
      for (let node of item.nodes()) {
        if (node.source.children.length > 1) {
          node.source.children.reverse();
        }
        for (let childNode of node.childNodes()) {
          if (childNode.isAnchor()) {
            const { args } = childNode.source.base;
            const { handleIn, handleOut } = args;
            if (handleIn) {
              args.handleOut = handleIn;
            } else {
              delete args.handleOut;
            }
            if (handleOut) {
              args.handleIn = handleOut;
            } else {
              delete args.handleIn;
            }
          }
        }
      }
    }
  },
  enabled: () => {
    // The selection has something with children
    return globalState.project.selection
      .directlySelectedNodes()
      .items.some(({ node }) => node.source.children.length > 1);
  },
};
const createPathMenuItem: MenuItem = {
  label: "Create Path",
  action: () => {
    const wrappedElement = wrapProjectSelectionInModifier(PathDefinition).source;
    wrappedElement.stroke = new Instance(StrokeDefinition);
  },
  enabled: hasSelection,
};
const createCompoundPathMenuItem: MenuItem = {
  label: "Create Compound Path",
  action: () => {
    const wrappedElement = wrapProjectSelectionInModifier(CompoundPathDefinition).source;
    wrappedElement.stroke = new Instance(StrokeDefinition);
  },
  enabled: hasSelection,
};
const releaseCompoundPathMenuItem: MenuItem = {
  label: "Release Compound Path",
  action: () => unwrapSelectedNodesWithDefinition(CompoundPathDefinition),
  enabled: hasSelectionWithCompoundPath,
};
const bringToFrontMenuItem: MenuItem = {
  label: "Bring to Front",
  action: bringSelectedNodesToFront,
  accelerator: new Accelerator("]", { command: true, option: true }),
  enabled: hasSelection,
};
const bringForwardMenuItem: MenuItem = {
  label: "Bring Forward",
  action: bringSelectedNodesForward,
  accelerator: new Accelerator("]", { command: true }),
  enabled: hasSelection,
};
const sendBackwardMenuItem: MenuItem = {
  label: "Send Backward",
  action: sendSelectedNodesBackward,
  accelerator: new Accelerator("[", { command: true }),
  enabled: hasSelection,
};
const sendToBackMenuItem: MenuItem = {
  label: "Send to Back",
  action: sendSelectedNodesToBack,
  accelerator: new Accelerator("[", { command: true, option: true }),
  enabled: hasSelection,
};
const canFlipSelection = () => {
  return hasSelection() && !globalState.project.selection.isScaleLocked();
};
const flipHorizontalMenuItem: MenuItem = {
  label: "Flip Horizontal",
  action: () => {
    const nodes = globalState.project.selection.allNodes().mutables().toNodes();
    flipNodes(nodes, new Vec(1, 0));
  },
  enabled: canFlipSelection,
};
const flipVerticalMenuItem: MenuItem = {
  label: "Flip Vertical",
  action: () => {
    const nodes = globalState.project.selection.allNodes().mutables().toNodes();
    flipNodes(nodes, new Vec(0, 1));
  },
  enabled: canFlipSelection,
};

const alignLeftMenuItem: MenuItem = {
  label: "Align Left",
  action: () => {
    const nodes = globalState.project.selection.allNodes().mutables().toNodes();
    alignNodes(nodes, "left");
  },
  enabled: hasMultipleSelection,
};
const alignCenterMenuItem: MenuItem = {
  label: "Align Center",
  action: () => {
    const nodes = globalState.project.selection.allNodes().mutables().toNodes();
    alignNodes(nodes, "center");
  },
  enabled: hasMultipleSelection,
};
const alignRightMenuItem: MenuItem = {
  label: "Align Right",
  action: () => {
    const nodes = globalState.project.selection.allNodes().mutables().toNodes();
    alignNodes(nodes, "right");
  },
  enabled: hasMultipleSelection,
};
const alignTopMenuItem: MenuItem = {
  label: "Align Top",
  action: () => {
    const nodes = globalState.project.selection.allNodes().mutables().toNodes();
    alignNodes(nodes, "top");
  },
  enabled: hasMultipleSelection,
};
const alignVerticalCenterMenuItem: MenuItem = {
  label: "Align Vertical Center",
  action: () => {
    const nodes = globalState.project.selection.allNodes().mutables().toNodes();
    alignNodes(nodes, "middle");
  },
  enabled: hasMultipleSelection,
};
const alignBottomMenuItem: MenuItem = {
  label: "Align Bottom",
  action: () => {
    const nodes = globalState.project.selection.allNodes().mutables().toNodes();
    alignNodes(nodes, "bottom");
  },
  enabled: hasMultipleSelection,
};
const alignMenu: MenuItem = {
  label: "Align Selection",
  enabled: hasMultipleSelection,
  submenu: () => [
    alignLeftMenuItem,
    alignCenterMenuItem,
    alignRightMenuItem,
    alignTopMenuItem,
    alignVerticalCenterMenuItem,
    alignBottomMenuItem,
  ],
};

const showContentsMenuItem: MenuItem = {
  label: "Show Contents In Outline",
  action: () => {
    const selected = globalState.project.selection.allNodes().items;
    for (const selectable of selected) {
      if (selectable.node.hasChildNodes()) {
        globalState.project.expandNode(selectable.node);
      }
    }
  },
  enabled: () => {
    const selected = globalState.project.selection.allNodes().items;
    for (const selectable of selected) {
      if (selectable.node.hasChildNodes()) return true;
    }
    return false;
  },
};

const extractAsComponentMenuItem: MenuItem = {
  label: "Extract as Component",
  action: extractAsComponent,
  enabled: hasSelection,
};
const convertToPathsMenuItem: MenuItem = {
  label: "Convert to Paths",
  action: convertSelectedNodesToPaths,
  enabled: hasSelection,
};

const cutMenuItem: MenuItem = {
  label: "Cut",
  action: menuCutSelection,
  accelerator: new Accelerator("X", { command: true, displayOnly: true }),
  enabled: hasSelection,
};
const copyMenuItem: MenuItem = {
  label: "Copy",
  action: menuCopySelection,
  accelerator: new Accelerator("C", { command: true, displayOnly: true }),
  enabled: hasSelection,
};
const pasteMenuItem: MenuItem = {
  label: "Paste",
  action: menuPasteSelection,
  accelerator: new Accelerator("V", { command: true, displayOnly: true }),
};

const deleteMenuItem: MenuItem = {
  label: "Delete",
  action: () => globalState.deleteSelection(),
  accelerator: new Accelerator("backspace"),
  enabled: hasSelection,
};

const isAnySelectedNodeVisible = () => {
  return globalState.project.selection.allNodes().items.some((item) => item.node.isVisible());
};
const visibleMenuItem: MenuItem = {
  label: [m(Icon20, { icon: "visible" }), "Visible"],
  action: () => {
    const selectedNodes = globalState.project.selection.allNodes();
    const isVisible = !selectedNodes.items.some((item) => item.node.isVisible());
    for (let item of selectedNodes.items) {
      item.node.source.isVisible = isVisible;
    }
  },
  icon: () => isAnySelectedNodeVisible() && m(Icon20, { icon: "check" }),
  enabled: hasSelection,
};

const isAnySelectedNodeLocked = () => {
  return globalState.project.selection.allNodes().items.some((item) => item.node.isLocked());
};
const lockedMenuItem: MenuItem = {
  label: [m(Icon20, { icon: "locked" }), "Locked"],
  action: () => {
    const selectedNodes = globalState.project.selection.allNodes();
    const isLocked = !selectedNodes.items.some((item) => item.node.isLocked());
    for (let item of selectedNodes.items) {
      item.node.source.isLocked = isLocked;
    }
  },
  icon: () => isAnySelectedNodeLocked() && m(Icon20, { icon: "check" }),
  enabled: hasSelection,
};

const isAnySelectedNodeGuidesDisplay = (display: GuidesDisplay) => {
  return globalState.project.selection
    .allNodes()
    .items.some((item) => item.node.source.guidesDisplay === display);
};

const guidesDisplayMenuItem = (label: string, icon: IconName, display: GuidesDisplay): MenuItem => {
  return {
    label: [m(Icon20, { icon }), label],
    action: () => {
      const selectedNodes = globalState.project.selection.allNodes();
      for (let item of selectedNodes.items) {
        item.node.source.guidesDisplay = display;
      }
    },
    icon: () => isAnySelectedNodeGuidesDisplay(display) && m(Icon20, { icon: "check" }),
    enabled: hasSelection,
  };
};
const displayMenu: MenuItem = {
  label: "Display",
  submenu: () => [
    visibleMenuItem,
    lockedMenuItem,

    { type: "separator" },

    guidesDisplayMenuItem("Show Guides", "show_guides", "show"),
    guidesDisplayMenuItem("Show Shapes As Guides", "all_guides", "show-all-as-guides"),
    guidesDisplayMenuItem("Hide Guides", "hide_guides", "hide"),
  ],
};

const selectionRightClickMenu = [
  cutMenuItem,
  copyMenuItem,
  pasteMenuItem,
  duplicateMenuItem,
  deleteMenuItem,

  { type: "separator" },

  groupMenuItem,
  ungroupMenuItem,
  ungroupAllMenuItem,

  { type: "separator" },

  bringToFrontMenuItem,
  bringForwardMenuItem,
  sendBackwardMenuItem,
  sendToBackMenuItem,

  { type: "separator" },

  flipHorizontalMenuItem,
  flipVerticalMenuItem,

  { type: "separator" },

  alignMenu,

  { type: "separator" },

  extractAsComponentMenuItem,
  convertToPathsMenuItem,

  { type: "separator" },

  createPathMenuItem,
  createCompoundPathMenuItem,
  releaseCompoundPathMenuItem,

  { type: "separator" },

  reversePathDirectionMenuItem,
  splitPathAtAnchorMenuItem,

  { type: "separator" },

  displayMenu,

  { type: "separator" },

  showContentsMenuItem,
];

export const createSelectionRightClickMenu = (event: PointerEvent) => {
  createRightClickPopupMenu(event, selectionRightClickMenu);
};

const PublishedStatusCheck: m.Component = {
  view() {
    const publishedSnapshotId = globalState.storage.getPublishedSnapshotId();
    const currentSnapshotId = globalState.storage.getCurrentSnapshotId();
    const className = classNames({
      "is-shared": typeof publishedSnapshotId === "number",
      "is-behind": publishedSnapshotId !== currentSnapshotId,
    });
    return m(".icon.published-status", { className }, m(Icon20, { icon: "check" }));
  },
};

interface TopMenuItem {
  label: () => m.Children;
  id: string;
  icon?: IconName;
  // TODO: submenu doesn't need to be a function once we make the Modifiers side
  // panel.
  submenu: () => MenuItem[];
  visible?: () => boolean;
}

const exportAction = (format: ExportFormat) => {
  if (globalState.storage.hasWritePermission()) {
    globalState.saveVersion();
  }

  const component = globalState.project.focusedComponent();
  if (!component) return;
  const options = exportOptionsForComponents([component], format);

  if (format === "cuttle.svg") {
    downloadFileWithExportOptionsAndErrorToast(options);
  } else {
    openExportOptionsModal(options);
  }
};
const printAction = () => {
  if (globalState.storage.hasWritePermission()) {
    globalState.saveVersion();
  }
  printFocusedComponent();
};

const topMenuItems: TopMenuItem[] = [
  {
    label: () => "Cuttle.xyz",
    id: "cuttle",
    icon: "logo",
    submenu: () => [
      {
        custom: () =>
          m(BigLinkMenuItem, {
            title: "My Projects",
            href: "/dashboard",
          }),
      },

      { type: "separator" },

      {
        custom: () =>
          m(BigLinkMenuItem, {
            title: "Explore",
            description: "Publicly shared projects from the community",
            href: "/explore",
          }),
      },
    ],
  },
  {
    label: () => "File",
    id: "file",
    submenu: () => [
      {
        label: "New",
        action: newProject,
        icon: () => m(Icon20, { icon: "new_tab" }),
      },

      { type: "separator" },

      {
        label: "Import SVG or Image…",
        action: async () => {
          const files = await pickFilesForImport();
          importFilesToProject(files);
        },
        enabled: hasFocusedComponent,
      },
      {
        label: ["Overwrite from Project…", m(".feature-tag", "ADMIN")],
        action: () => {
          createPopupPrompt({
            label: "Project URL",
            placeholder: "https://cuttle.xyz/...",
            onsubmit: (url) => {
              const { projectId } = urlParts(url);
              if (projectId) {
                globalState.overwriteWithProject(projectId);
              }
            },
            spawnFrom: { x: 20, y: 20 },
            autofocus: true,
          });
        },
        visible: showAdminFeatures,
      },
      {
        label: ["Export Cuttle SVG", m(".feature-tag", "ADMIN")],
        action: () => exportAction("cuttle.svg"),
        visible: showAdminFeatures,
        enabled: hasFocusedComponent,
      },
      {
        label: ["Export Project Examples", m(".feature-tag", "ADMIN")],
        action: exportProjectExamples,
        visible: showAdminFeatures,
      },
      {
        label: [`Show Admin (Hold ${isMacPlatform() ? "⌥" : "Alt"})`, m(".feature-tag", "ADMIN")],
        action: toggleShowAdmin,
        visible: showAdminFeatures,
        icon: () => globalState.deviceStorage.showAdminFeatures && m(Icon20, { icon: "check" }),
      },
      {
        label: ["Local Pro Testing", m(".feature-tag", "ADMIN")],
        action: toggleAdminProTesting,
        visible: showAdminFeatures,
        icon: () => accountState.featureFlags.hasProFeatures && m(Icon20, { icon: "check" }),
      },

      { type: "separator" },

      {
        label: "Export SVG…",
        action: () => exportAction("svg"),
        enabled: hasFocusedComponent,
      },
      {
        label: "Export PDF…",
        action: () => exportAction("pdf"),
        enabled: hasFocusedComponent,
      },
      {
        label: "Export PNG…",
        action: () => exportAction("png"),
        enabled: hasFocusedComponent,
      },
      {
        label: "Export DXF…",
        action: () => exportAction("dxf"),
        enabled: hasFocusedComponent,
      },

      { type: "separator" },

      {
        label: "Print…",
        accelerator: new Accelerator("p", { command: true }),
        action: printAction,
        enabled: hasFocusedComponent,
      },

      { type: "separator" },

      {
        label: "Save a Version",
        action: async () => {
          await globalState.saveVersion();
          await globalState.storage.refreshSnapshots();
          globalState.isVersionHistoryOpen = true;
          m.redraw();
        },
        enabled: () => globalState.storage.hasWritePermission(),
      },
      {
        label: "Browse Version History",
        action: () => (globalState.isVersionHistoryOpen = true),
        enabled: () => globalState.storage.hasWritePermission(),
      },
    ],
  },

  {
    label: () => "Edit",
    id: "edit",
    submenu: () => [
      {
        label: "Undo",
        accelerator: new Accelerator("z", { command: true }),
        enabled() {
          return globalState.hasUndo();
        },
        action() {
          globalState.undo();
        },
      },
      {
        label: "Redo",
        accelerator: new Accelerator("z", { command: true, shift: true }),
        enabled() {
          return globalState.hasRedo();
        },
        action() {
          globalState.redo();
        },
      },

      { type: "separator" },

      {
        label: "Select All",
        accelerator: new Accelerator("a", { command: true }),
        action: () => {
          if (globalState.project.focus instanceof ComponentFocus) {
            globalState.project.selectNodes(
              globalState.project.focus.node.childNodes().filter((node) => {
                if (!node.isVisible() || node.source.guidesDisplay === "show-all-as-guides") {
                  return false;
                }
                const traceResult = globalState.traceForNode(node)?.result;
                return traceResult && traceResult.looseBoundingBox();
              })
            );
          }
        },
        enabled: () => globalState.project.focus instanceof ComponentFocus,
      },

      { type: "separator" },

      cutMenuItem,
      copyMenuItem,
      pasteMenuItem,
      duplicateMenuItem,
      deleteMenuItem,

      { type: "separator" },

      groupMenuItem,
      ungroupMenuItem,
      ungroupAllMenuItem,

      { type: "separator" },

      bringToFrontMenuItem,
      bringForwardMenuItem,
      sendBackwardMenuItem,
      sendToBackMenuItem,

      { type: "separator" },

      flipHorizontalMenuItem,
      flipVerticalMenuItem,

      { type: "separator" },

      alignMenu,

      { type: "separator" },

      extractAsComponentMenuItem,
      convertToPathsMenuItem,

      { type: "separator" },

      createPathMenuItem,
      createCompoundPathMenuItem,
      releaseCompoundPathMenuItem,

      { type: "separator" },

      reversePathDirectionMenuItem,
      splitPathAtAnchorMenuItem,

      { type: "separator" },

      displayMenu,

      { type: "separator" },

      showContentsMenuItem,
    ],
  },

  {
    label: () => "View",
    id: "view",
    submenu: () => [
      {
        label: "Zoom In",
        accelerator: new Accelerator("=", { command: "optional" }),
        action: () => globalState.zoomViewport(Math.SQRT2, true),
        enabled: hasFocusedComponent,
      },
      {
        label: "Zoom Out",
        accelerator: new Accelerator("-", { command: "optional" }),
        action: () => globalState.zoomViewport(1 / Math.SQRT2, true),
        enabled: hasFocusedComponent,
      },

      { type: "separator" },

      {
        label: "Zoom to Real Size",
        action: () => {
          let ppi = globalState.deviceStorage.currentScreenPPI();
          if (ppi) {
            globalState.zoomViewportToPixelsPerInch(ppi);
          } else {
            globalState.openRealSizeCalibration();
          }
        },
        accelerator: new Accelerator("0", { command: true }),
        enabled: hasFocusedComponent,
      },
      {
        label: "Calibrate This Screen…",
        action: () => globalState.openRealSizeCalibration(),
        enabled: hasFocusedComponent,
      },

      { type: "separator" },

      {
        label: "Zoom to Fit All",
        accelerator: new Accelerator("f", { command: true, shift: true }),
        action: () => globalState.zoomViewportToFitAll(),
        enabled: hasFocusedComponent,
      },
      {
        label: "Zoom to Fit Focused",
        action: () => globalState.zoomViewportToFitFocused(),
        enabled: hasFocusedComponent,
      },
      {
        label: "Zoom to Fit Selected",
        accelerator: new Accelerator("f", { command: true }),
        action: () => globalState.zoomViewportToFitSelected(),
        enabled: hasFocusedComponent,
      },

      { type: "separator" },
      {
        label: [m(Icon20, { icon: "trackpad" }), "Trackpad: Scroll to Pan"],
        action: () => (globalState.deviceStorage.scrollBehavior = "pan"),
        icon: () =>
          globalState.deviceStorage.scrollBehavior === "pan" ? m(Icon20, { icon: "check" }) : null,
        tooltip: () => {
          const metaKey = isMacPlatform() ? "⌘" : "Ctrl";
          return m("div", [
            m("div", "Scrolling will pan the canvas."),
            m("div", `Pinch to zoom, or hold ${metaKey} and scroll to zoom.`),
          ]);
        },
      },
      {
        label: [m(Icon20, { icon: "mouse" }), "Mouse: Scroll to Zoom"],
        action: () => (globalState.deviceStorage.scrollBehavior = "zoom"),
        icon: () =>
          globalState.deviceStorage.scrollBehavior === "zoom" ? m(Icon20, { icon: "check" }) : null,
        tooltip: () => {
          const metaKey = isMacPlatform() ? "⌘" : "Ctrl";
          return m("div", [
            m("div", "Scrolling will zoom the canvas."),
            m("div", "Drag with right mouse button to pan."),
          ]);
        },
      },

      { type: "separator" },

      {
        label: "Show Transform Box",
        action: () => {
          globalState.project.isTransformBoxEnabled = !globalState.project.isTransformBoxEnabled;
        },
        icon: () =>
          globalState.project.isTransformBoxEnabled ? m(Icon20, { icon: "check" }) : null,
      },

      { type: "separator" },

      {
        label: "Show Grid",
        action: () => {
          globalState.project.isGridEnabled = !globalState.project.isGridEnabled;
        },
        icon: () => (globalState.project.isGridEnabled ? m(Icon20, { icon: "check" }) : null),
      },
      {
        label: "Snap to Grid",
        action: () =>
          (globalState.deviceStorage.gridSnappingEnabled =
            !globalState.deviceStorage.gridSnappingEnabled),
        icon: () =>
          globalState.deviceStorage.gridSnappingEnabled ? m(Icon20, { icon: "check" }) : null,
        accelerator: new Accelerator("u", { command: true, shift: true }),
      },
      {
        label: "Snap to Geometry",
        action: () =>
          (globalState.deviceStorage.geometrySnappingEnabled =
            !globalState.deviceStorage.geometrySnappingEnabled),
        icon: () =>
          globalState.deviceStorage.geometrySnappingEnabled ? m(Icon20, { icon: "check" }) : null,
        accelerator: new Accelerator("u", { command: true }),
      },
    ],
  },

  {
    label: () => "Modify",
    id: "modify",
    submenu: () => {
      const modifyMenuItems: MenuItem[] = nonNull([
        modifierMenuItem("Mirror Repeat", MirrorRepeatDefinition),
        modifierMenuItem("Linear Repeat", LinearRepeatDefinition),
        modifierMenuItem("Rotational Repeat", RotationalRepeatDefinition),
        modifierMenuItem("Tile Repeat", TileRepeatDefinition),
        modifierMenuItem("Transform Repeat", TransformRepeatDefinition),

        { type: "separator" },

        modifierMenuItem("Round Corners", RoundCornersDefinition),
        modifierMenuItem("Dashed Lines", DashedLinesDefinition),
        modifierMenuItem("Text Along Path", TextAlongPathDefinition),
        modifierMenuItem("Text Within Box", TextWithinBoxDefinition),

        { type: "separator" },

        modifierMenuItem("Merge Paths", MergePathsDefinition),

        { type: "separator" },

        modifierMenuItem("Outline Stroke", OutlineStrokeDefinition),
        modifierMenuItem("Expand (Offset)", ExpandDefinition),
        modifierMenuItem("Contract (Inset)", ContractDefinition),

        { type: "separator" },

        modifierMenuItem("Boolean Union (Weld)", BooleanUnionDefinition),
        modifierMenuItem("Boolean Difference", BooleanDifferenceDefinition),
        modifierMenuItem("Boolean Intersect", BooleanIntersectDefinition),

        { type: "separator" },

        modifierMenuItem("Mask", MaskDefinition),
        modifierMenuItem("Fit Within", FitWithinDefinition),

        { type: "separator" },

        modifierMenuItem("Flatten", FlattenDefinition),
        modifierMenuItem("Layered Stack", LayeredStackDefinition),

        { type: "separator" },

        modifierMenuItem("Remove Holes", RemoveHolesDefinition),
        modifierMenuItem("Remove Overlaps", RemoveOverlapsDefinition),
        modifierMenuItem("Weld And Score", WeldAndScoreDefinition, { isBetaFeature: true }),

        { type: "separator" },

        {
          label: "Advanced",
          submenu: () => [
            modifierMenuItem("Visible", VisibleDefinition),
            modifierMenuItem("Warp Coordinates", WarpCoordinatesDefinition),
          ],
        },

        { type: "separator" },

        {
          label: "New Modifier",
          icon: () => m(Icon20, { icon: "plus" }),
          action: badgeSelectedNodesWithNewModifierAction,
          enabled: hasSelection,
        },
      ]);

      // Custom Modifiers
      if (globalState.project.modifiers.length > 0) {
        modifyMenuItems.push({ type: "separator" });
        modifyMenuItems.push(
          ...globalState.project.modifiers.map((modifier) => {
            return {
              label: modifier.name,
              action: badgeSelectedNodesWithModifierAction(modifier),
              enabled: hasSelection,
            };
          })
        );
      }

      // Usually we would use `enabled` to keep the item in the menu, but this
      // is a special case for our #coding friends that will not be used very
      // often. TODO: better modifier management.
      const graph = new DependencyGraph(globalState.project);
      const unusedModifiers = graph.unusedModifiers();
      if (unusedModifiers.length > 0) {
        modifyMenuItems.push({ type: "separator" });
        modifyMenuItems.push({
          label: "Remove Unused Modifiers",
          action: removeUnusedModifiersAction,
        });
      }

      return modifyMenuItems;
    },
  },

  {
    label: () => "Help",
    id: "help",
    submenu: () => [
      {
        custom: () =>
          m(BigLinkMenuItem, {
            title: "Learn Cuttle",
            description: "Getting started guide, video tutorials, and reference documentation",
            href: "/learn/video-tutorials",
          }),
      },
      {
        // Hide Discord link from K-12 users
        visible: () => accountState.loggedInUser?.subscription.plan !== "K-12 Education",
        custom: () =>
          m(BigLinkMenuItem, {
            title: "Discord Chat Room",
            description: "Chat with us and fellow Cuttle users. Great place to ask questions!",
            href: "https://discord.gg/QRsB3VT",
          }),
      },
    ],
  },

  {
    label: () => ["Share", m(PublishedStatusCheck)],
    id: "share",
    submenu: () => [
      {
        custom: () => m(ShareMenu),
      },
    ],
    visible: () => globalState.storage.hasWritePermission(),
  },
];

const registerKeyboardShortcuts = () => {
  // Everything from the menu bar
  topMenuItems.forEach((topMenuItem) => {
    topMenuItem.submenu().forEach((menuItem) => {
      // TODO: walk menuItem.submenu
      if (menuItem.accelerator) {
        const action = () => {
          if (menuItem.enabled && !menuItem.enabled()) return;
          menuItem.action?.();
        };
        registerKeyboardShortcut(menuItem.accelerator, action);
      }
    });
  });

  // Canvas tools
  registerKeyboardShortcut(new Accelerator("v"), () => {
    globalState.activateTool("Select");
  });
  registerKeyboardShortcut(new Accelerator("p"), () => {
    globalState.activateTool("Pen");
  });
  registerKeyboardShortcut(new Accelerator("r"), () => {
    globalState.activateTool("Rotate");
  });
  registerKeyboardShortcut(new Accelerator("s"), () => {
    globalState.activateTool("Scale");
  });
  registerKeyboardShortcut(new Accelerator("g"), () => {
    globalState.deviceStorage.gridSnappingEnabled = !globalState.deviceStorage.gridSnappingEnabled;
  });
  registerKeyboardShortcut(new Accelerator("u"), () => {
    globalState.deviceStorage.geometrySnappingEnabled =
      !globalState.deviceStorage.geometrySnappingEnabled;
  });

  // Cut, Copy, Paste, Delete
  //
  // We'll handle delete, cut, copy, and paste here as well as in our menu. The
  // accelerators in the menu have different logic than these event listeners,
  // to be able to support our localStorage "paste" workaround.
  //
  // TODO: Make cut, copy, paste work when you have selected badge modifiers,
  // not just nodes.
  registerKeyboardShortcut(new Accelerator("delete"), () => {
    globalState.deleteSelection();
  });

  document.addEventListener("cut", onDocumentCut);
  document.addEventListener("copy", onDocumentCopy);
  document.addEventListener("paste", onDocumentPaste, true);
};

let openTopMenuItem: TopMenuItem | undefined;
let createdPopup: CreatedPopup | undefined;

interface TopMenuAttrs {
  rightPanelWidth: number;
}
export const TopMenu: m.Component<TopMenuAttrs> = {
  oninit() {
    registerKeyboardShortcuts();
  },
  view() {
    return m(".top-menu", [
      m(
        ".top-menu-left",
        topMenuItems.map((topMenuItem) =>
          m(TopMenuItemComponent, { topMenuItem, key: topMenuItem.id })
        )
      ),
      m(RectWatcher, {
        className: "top-menu-right",
        view(rect) {
          const canvasRect = globalState.canvasRectPixels;
          const menuLeftWidth = window.innerWidth - rect.width;
          const projectMinWidthPixels =
            canvasRect.width + rulerWidthPixels - 2 * Math.max(0, menuLeftWidth - canvasRect.left);
          return m(".top-menu-right", m(TopMenuProject, { minWidthPixels: projectMinWidthPixels }));
        },
      }),
    ]);
  },
};

const TopMenuItemComponent: m.Component<{ topMenuItem: TopMenuItem }> = {
  view(vnode) {
    const { topMenuItem } = vnode.attrs;

    if (topMenuItem.visible?.() === false) {
      return null;
    }

    const openMenu = () => {
      const menuItems = topMenuItem.submenu();
      openTopMenuItem = topMenuItem;
      createdPopup = createPopupMenu({
        menuItems,
        spawnFrom: domForVnode(vnode),
        className: "top-menu-popup",
        placement: "bottom-start",
        onclose: () => {
          openTopMenuItem = undefined;
          createdPopup = undefined;
        },
        yMin: PANEL_TOP_HEIGHT,
      });
    };

    const onpointerdown = () => {
      if (openTopMenuItem && createdPopup) {
        createdPopup.close();
      } else {
        openMenu();
      }
    };
    const onpointerenter = () => {
      if (openTopMenuItem && createdPopup) {
        createdPopup.close();
        openMenu();
      }
    };
    const className = classNames({
      open: openTopMenuItem === topMenuItem,
      "has-icon": Boolean(topMenuItem.icon),
    });
    return m(
      ".top-menu-item",
      {
        ["data-automation-id"]: `top-menu-item-${topMenuItem.id}`,
        className,
        onpointerdown,
        onpointerenter,
      },
      [
        topMenuItem.icon && m(".top-menu-item-icon", m(Icon20, { icon: topMenuItem.icon })),
        topMenuItem.label(),
      ]
    );
  },
};

interface BigLinkMenuItemAttrs {
  title: string;
  href: string;
  description?: string;
}
const BigLinkMenuItem: m.Component<BigLinkMenuItemAttrs> = {
  view({ attrs: { title, description, href } }) {
    return m("a.top-menu-biglink-item", { href, target: "_blank" }, [
      m(".top-menu-biglink-title", [
        title,
        m(".top-menu-item-icon", m(Icon20, { icon: "new_tab" })),
      ]),
      description && m(".top-menu-biglink-description", description),
    ]);
  },
};
